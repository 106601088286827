// @generated by protobuf-ts 2.9.4 with parameter long_type_string,optimize_code_size
// @generated from protobuf file "soulfire/login.proto" (package "soulfire.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message soulfire.v1.LoginRequest
 */
export interface LoginRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
}
/**
 * @generated from protobuf message soulfire.v1.NextAuthFlowResponse
 */
export interface NextAuthFlowResponse {
    /**
     * @generated from protobuf field: string auth_flow_token = 1;
     */
    authFlowToken: string;
    /**
     * @generated from protobuf oneof: next
     */
    next: {
        oneofKind: "emailCode";
        /**
         * @generated from protobuf field: soulfire.v1.NextAuthFlowResponse.EmailCode email_code = 2;
         */
        emailCode: NextAuthFlowResponse_EmailCode;
    } | {
        oneofKind: "success";
        /**
         * @generated from protobuf field: soulfire.v1.NextAuthFlowResponse.Success success = 3;
         */
        success: NextAuthFlowResponse_Success;
    } | {
        oneofKind: "failure";
        /**
         * @generated from protobuf field: soulfire.v1.NextAuthFlowResponse.Failure failure = 4;
         */
        failure: NextAuthFlowResponse_Failure;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message soulfire.v1.NextAuthFlowResponse.EmailCode
 */
export interface NextAuthFlowResponse_EmailCode {
}
/**
 * @generated from protobuf message soulfire.v1.NextAuthFlowResponse.Success
 */
export interface NextAuthFlowResponse_Success {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * @generated from protobuf message soulfire.v1.NextAuthFlowResponse.Failure
 */
export interface NextAuthFlowResponse_Failure {
    /**
     * @generated from protobuf field: soulfire.v1.NextAuthFlowResponse.Failure.Reason reason = 1;
     */
    reason: NextAuthFlowResponse_Failure_Reason;
}
/**
 * @generated from protobuf enum soulfire.v1.NextAuthFlowResponse.Failure.Reason
 */
export enum NextAuthFlowResponse_Failure_Reason {
    /**
     * @generated from protobuf enum value: INVALID_CODE = 0;
     */
    INVALID_CODE = 0
}
/**
 * @generated from protobuf message soulfire.v1.EmailCodeRequest
 */
export interface EmailCodeRequest {
    /**
     * @generated from protobuf field: string auth_flow_token = 1;
     */
    authFlowToken: string;
    /**
     * @generated from protobuf field: string code = 2;
     */
    code: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class LoginRequest$Type extends MessageType<LoginRequest> {
    constructor() {
        super("soulfire.v1.LoginRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.LoginRequest
 */
export const LoginRequest = new LoginRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NextAuthFlowResponse$Type extends MessageType<NextAuthFlowResponse> {
    constructor() {
        super("soulfire.v1.NextAuthFlowResponse", [
            { no: 1, name: "auth_flow_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email_code", kind: "message", oneof: "next", T: () => NextAuthFlowResponse_EmailCode },
            { no: 3, name: "success", kind: "message", oneof: "next", T: () => NextAuthFlowResponse_Success },
            { no: 4, name: "failure", kind: "message", oneof: "next", T: () => NextAuthFlowResponse_Failure }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.NextAuthFlowResponse
 */
export const NextAuthFlowResponse = new NextAuthFlowResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NextAuthFlowResponse_EmailCode$Type extends MessageType<NextAuthFlowResponse_EmailCode> {
    constructor() {
        super("soulfire.v1.NextAuthFlowResponse.EmailCode", []);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.NextAuthFlowResponse.EmailCode
 */
export const NextAuthFlowResponse_EmailCode = new NextAuthFlowResponse_EmailCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NextAuthFlowResponse_Success$Type extends MessageType<NextAuthFlowResponse_Success> {
    constructor() {
        super("soulfire.v1.NextAuthFlowResponse.Success", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.NextAuthFlowResponse.Success
 */
export const NextAuthFlowResponse_Success = new NextAuthFlowResponse_Success$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NextAuthFlowResponse_Failure$Type extends MessageType<NextAuthFlowResponse_Failure> {
    constructor() {
        super("soulfire.v1.NextAuthFlowResponse.Failure", [
            { no: 1, name: "reason", kind: "enum", T: () => ["soulfire.v1.NextAuthFlowResponse.Failure.Reason", NextAuthFlowResponse_Failure_Reason] }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.NextAuthFlowResponse.Failure
 */
export const NextAuthFlowResponse_Failure = new NextAuthFlowResponse_Failure$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmailCodeRequest$Type extends MessageType<EmailCodeRequest> {
    constructor() {
        super("soulfire.v1.EmailCodeRequest", [
            { no: 1, name: "auth_flow_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
}
/**
 * @generated MessageType for protobuf message soulfire.v1.EmailCodeRequest
 */
export const EmailCodeRequest = new EmailCodeRequest$Type();
/**
 * @generated ServiceType for protobuf service soulfire.v1.LoginService
 */
export const LoginService = new ServiceType("soulfire.v1.LoginService", [
    { name: "login", options: {}, I: LoginRequest, O: NextAuthFlowResponse },
    { name: "emailCode", options: {}, I: EmailCodeRequest, O: NextAuthFlowResponse }
]);
